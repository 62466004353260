export default [
  {
    header: 'Pay',
  },
  {
    title: '充值',
    route: 'pay',
    icon: 'ColumnsIcon',
  },
]
