export default [
  {
    header: 'Dashboard',
  },

  {
    title: '用户中心',
    route: 'dashboard',
  },

]
